'use client';

import React from 'react';

import { ClientEnv } from '@lib/services/env';

const ClientEnvCtx = React.createContext<ClientEnv>({} as ClientEnv);

interface EnvProviderProps extends React.PropsWithChildren {
  env: ClientEnv;
}

const EnvProvider: React.FC<EnvProviderProps> = ({ env, children }) => {
  return <ClientEnvCtx.Provider value={env}>{children}</ClientEnvCtx.Provider>;
};

const useEnv = () => {
  const ctx = React.useContext(ClientEnvCtx);
  if (!ctx) {
    throw new Error('useEnv must be used within a EnvProvider');
  }
  return ctx;
};

export { EnvProvider, useEnv };
export type { ClientEnvCtx };
